import { CALL_INTAKE_API } from 'middleware/intakeApi';

import {
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_NOTE_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ASSIGN_INTAKE_RECORD_FAILURE,
  ASSIGN_INTAKE_RECORD_REQUEST,
  ASSIGN_INTAKE_RECORD_SUCCESS,
  CLEAR_CACHED_INTAKE_RECORD,
  CLEAR_FOCUSED_GROUP,
  CLEAR_INTAKE_RECORD_VALIDATION,
  DELETE_INTAKE_RECORD_FAILURE,
  DELETE_INTAKE_RECORD_REQUEST,
  DELETE_INTAKE_RECORD_SUCCESS,
  GET_INTAKE_FORM_METADATA_FAILURE,
  GET_INTAKE_FORM_METADATA_REQUEST,
  GET_INTAKE_FORM_METADATA_SUCCESS,
  GET_INTAKE_RECORD_FAILURE,
  GET_INTAKE_RECORD_REQUEST,
  GET_INTAKE_RECORD_SUCCESS,
  GET_INTAKE_SUMMARIES_FAILURE,
  GET_INTAKE_SUMMARIES_REQUEST,
  GET_INTAKE_SUMMARIES_SUCCESS,
  GET_SURVEY_INFO_FAILURE, 
  GET_SURVEY_INFO_REQUEST, 
  GET_SURVEY_INFO_SUCCESS,
  GET_TEMP_INTAKE_FAILURE, 
  GET_TEMP_INTAKE_REQUEST, 
  GET_TEMP_INTAKE_SUCCESS,
  GRANT_WEB_ACCESS_FAILURE,
  GRANT_WEB_ACCESS_REQUEST,
  GRANT_WEB_ACCESS_SUCCESS,
  IMPORT_INTAKE_RECORD_FAILURE,
  IMPORT_INTAKE_RECORD_REQUEST,
  IMPORT_INTAKE_RECORD_SUCCESS,
  SET_EE_INTAKE_FAILURE, 
  SET_EE_INTAKE_STATUS, 
  SET_EE_INTAKE_SUCCESS,
  SET_FOCUSED_GROUP,
  SET_FOCUSED_TAB,
  SET_INTAKE_RECORD_URGENCY_FAILURE,
  SET_INTAKE_RECORD_URGENCY_REQUEST,
  SET_INTAKE_RECORD_URGENCY_SUCCESS,
  SET_VALIDATION_MODE,
  SUBMIT_INTAKE_RECORD_FAILURE,
  SUBMIT_INTAKE_RECORD_REQUEST,
  SUBMIT_INTAKE_RECORD_SUCCESS,
  SUBMIT_PNC_LEAD_FAILURE,
  SUBMIT_PNC_LEAD_REQUEST,
  SUBMIT_PNC_LEAD_SUCCESS,
  SUBMIT_PREMIA_LEAD_FAILURE,
  SUBMIT_PREMIA_LEAD_REQUEST,
  SUBMIT_PREMIA_LEAD_SUCCESS,
  SUBMIT_ROCKET_LEAD_FAILURE,
  SUBMIT_ROCKET_LEAD_REQUEST,
  SUBMIT_ROCKET_LEAD_SUCCESS,
  SUBMIT_VENDOR_LETTERS_FAILURE,
  SUBMIT_VENDOR_LETTERS_REQUEST,
  SUBMIT_VENDOR_LETTERS_SUCCESS,
  UPDATE_INTAKE_RECORD_FAILURE,
  UPDATE_INTAKE_RECORD_REQUEST,
  UPDATE_INTAKE_RECORD_SUCCESS,
  VALIDATE_INTAKE_RECORD_FAILURE, 
  VALIDATE_INTAKE_RECORD_REQUEST, 
  VALIDATE_INTAKE_RECORD_SUCCESS,
  RESEND_WELCOME_EMAIL_FAILURE,
  RESEND_WELCOME_EMAIL_REQUEST,
  RESEND_WELCOME_EMAIL_SUCCESS,
} from './types';

export const getIntakeFormMetadata = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTAKE_FORM_METADATA_REQUEST, GET_INTAKE_FORM_METADATA_SUCCESS, GET_INTAKE_FORM_METADATA_FAILURE],
      authenticated: true,
      endpoint: 'intakeRecords/metadata',
      method: 'GET',
    },
  };
};

export const getIntakeRecord = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTAKE_RECORD_REQUEST, GET_INTAKE_RECORD_SUCCESS, GET_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/${id}`,
      method: 'GET',
    },
  };
};

export const updateIntakeRecord = (record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_INTAKE_RECORD_REQUEST, UPDATE_INTAKE_RECORD_SUCCESS, UPDATE_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: 'intakeRecords',
      method: 'PUT',
      payload: record,
    },
  };
};

export const setIntakeRecordUrgency = (intakeRecordId, intakeRecordUrgency) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SET_INTAKE_RECORD_URGENCY_REQUEST, SET_INTAKE_RECORD_URGENCY_SUCCESS, SET_INTAKE_RECORD_URGENCY_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/urgency/${intakeRecordId}/${intakeRecordUrgency}`,
      method: 'POST',
      context: {
        intakeRecordId,
        intakeRecordUrgency,
      },
    },
  };
};

export const getIntakeSummaries = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTAKE_SUMMARIES_REQUEST, GET_INTAKE_SUMMARIES_SUCCESS, GET_INTAKE_SUMMARIES_FAILURE],
      authenticated: true,
      endpoint: 'intakeSummaries',
      method: 'GET',
    },
  };
};

export const addNoteToRecord = (note, record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [ADD_NOTE_REQUEST, ADD_NOTE_SUCCESS, ADD_NOTE_FAILURE],
      authenticated: true,
      endpoint: 'intakeNotes',
      method: 'POST',
      payload: {
        intakeRecordId: record.intakeRecordId,
        note,
      },
    },
  };
};

export const addCommentToRecord = (comment, record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [ADD_COMMENT_REQUEST, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAILURE],
      authenticated: true,
      endpoint: 'intakeAuthorizationComments',
      method: 'POST',
      payload: {
        intakeRecordId: record.intakeRecordId,
        ...comment,
      },
    },
  };
};

export const assignIntakeRecordToRelocationConsultant = (record, relocationConsultant) => {
  return {
    [CALL_INTAKE_API]: {
      types: [ASSIGN_INTAKE_RECORD_REQUEST, ASSIGN_INTAKE_RECORD_SUCCESS, ASSIGN_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `relocationConsultants/${relocationConsultant.id}/intakeRecords/${record.intakeRecordId}`,
      method: 'PUT',
    },
  };
};

export const deleteIntakeRecord = (record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_INTAKE_RECORD_REQUEST, DELETE_INTAKE_RECORD_SUCCESS, DELETE_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/${record.intakeRecordId}`,
      method: 'DELETE',
    },
  };
};

export const validateIntakeRecord = (record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [VALIDATE_INTAKE_RECORD_REQUEST, VALIDATE_INTAKE_RECORD_SUCCESS, VALIDATE_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/validate/${record.id}`,
      method: 'POST',
      payload: record,
    },
  };
};

export const submitIntakeRecord = (intakeRecordId, hasWebAccess, bankingInfoRequired) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SUBMIT_INTAKE_RECORD_REQUEST, SUBMIT_INTAKE_RECORD_SUCCESS, SUBMIT_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/submit/${intakeRecordId}`,
      method: 'POST',
      payload: { hasWebAccess, bankingInfoRequired },
    },
  };
};

export const submitRocketLead = (leadInfo, clientId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SUBMIT_ROCKET_LEAD_REQUEST, SUBMIT_ROCKET_LEAD_SUCCESS, SUBMIT_ROCKET_LEAD_FAILURE],
      authenticated: true,
      endpoint: `mortgageLeads/sendQuickenLoans/${clientId}`,
      method: 'POST',
      payload: leadInfo,
    },
  };
};

export const grantWebAccess = (webAccessInfo) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GRANT_WEB_ACCESS_REQUEST, GRANT_WEB_ACCESS_SUCCESS, GRANT_WEB_ACCESS_FAILURE],
      authenticated: true,
      endpoint: 'intakeRecords/grantWebAccess',
      method: 'POST',
      payload: webAccessInfo,
      context: { id: webAccessInfo.Id },
    },
  };
};

export const resendWelcomeEmail = (webAccessInfo) => {
  return {
    [CALL_INTAKE_API]: {
      types: [RESEND_WELCOME_EMAIL_REQUEST, RESEND_WELCOME_EMAIL_SUCCESS, RESEND_WELCOME_EMAIL_FAILURE],
      authenticated: true,
      endpoint: 'intakeRecords/resendWelcomeEmail',
      method: 'POST',
      payload: webAccessInfo,
      context: { id: webAccessInfo.Id },
    },
  };
};

export const submitPncLead = (leadInfo, clientId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SUBMIT_PNC_LEAD_REQUEST, SUBMIT_PNC_LEAD_SUCCESS, SUBMIT_PNC_LEAD_FAILURE],
      authenticated: true,
      endpoint: `mortgageLeads/sendPnc/${clientId}`,
      method: 'POST',
      payload: leadInfo,
    },
  };
};

export const submitPremiaLead = (leadInfo, clientId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SUBMIT_PREMIA_LEAD_REQUEST, SUBMIT_PREMIA_LEAD_SUCCESS, SUBMIT_PREMIA_LEAD_FAILURE],
      authenticated: true,
      endpoint: `mortgageLeads/sendPremia/${clientId}`, //ADD Client ID
      method: 'POST',
      payload: leadInfo,
    },
  };
};

export const submitVendorLetter = (MtAuthorizationId, letterID, vendorEmail, clientId, templateName) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SUBMIT_VENDOR_LETTERS_REQUEST, SUBMIT_VENDOR_LETTERS_SUCCESS, SUBMIT_VENDOR_LETTERS_FAILURE],
      authenticated: true,
      endpoint: `letters/sendletter/${clientId}`,
      method: 'POST',
      payload: { 
        LetterID: letterID,
        VendorEmail: vendorEmail,
        MtAuthorizationId: MtAuthorizationId,
        TemplateName: templateName
      } },
  };
};

export const setFocusedTab = (tab) => ({
  type: SET_FOCUSED_TAB,
  response: tab,
});

export const setFocusedGroup = (group) => ({
  type: SET_FOCUSED_GROUP,
  response: group,
});

export const clearFocusedGroup = () => ({
  type: CLEAR_FOCUSED_GROUP,
});

export const importIntakeRecord = (authId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [IMPORT_INTAKE_RECORD_REQUEST, IMPORT_INTAKE_RECORD_SUCCESS, IMPORT_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/import/${authId}`,
      method: 'POST',
    },
  };
};

export const clearCachedIntakeRecord = (id) => ({
  type: CLEAR_CACHED_INTAKE_RECORD,
  response: id,
});

export const clearIntakeRecordValidation = (id) => ({
  type: CLEAR_INTAKE_RECORD_VALIDATION,
});

export const setValidationMode = (mode) => ({
  type: SET_VALIDATION_MODE,
  response: mode,
});

export const getSurveyInfo = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_SURVEY_INFO_REQUEST, GET_SURVEY_INFO_SUCCESS, GET_SURVEY_INFO_FAILURE],
      authenticated: true,
      endpoint: `tempIntakeRecords/surveyBasicInfo/${id}`,
      method: 'GET',
    },
  };
};

export const getTempIntakeFormDetails = (id) => {
  console.log('get temp intake form');
  return {
    [CALL_INTAKE_API]: {
      types: [GET_TEMP_INTAKE_REQUEST, GET_TEMP_INTAKE_SUCCESS, GET_TEMP_INTAKE_FAILURE],
      authenticated: true,
      endpoint: `tempIntakeRecords/${id}`,
      method: 'GET',
    },
  };
};

export const setEEIntakeStatus = (id, status) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SET_EE_INTAKE_STATUS, SET_EE_INTAKE_SUCCESS, SET_EE_INTAKE_FAILURE],
      authenticated: true,
      endpoint: `tempIntakeRecords/setSurveyRequired/${id}/${status}`,
      method: 'PUT',
      payload: {
        id,
        status,
      },
    },
  };
};